import { buildState } from '@briebug/ngrx-auto-entity';
import { ILookup } from '@models/types';
import { createAction, props } from '@ngrx/store';
import { TranslationGroup } from '../translation-groups/translation-group.entity';
import { Translation } from './translation.entity';

export const loadTranslationsByGroupAndKey = createAction(
  '[Translation] Load By Group and Key',
  props<{ group: string; key: string }>()
);

export const showAddTranslation = createAction(
  '[Translation] Show Add Translation',
  props<{ template: Partial<Translation> }>()
);
export const promptAddTranslation = createAction(
  '[Translation] Prompt Add Translation',
  props<{ template: Partial<Translation>; languages: ILookup[] }>()
);
export const cannotPromptAddTranslation = createAction('[Translation] Cannot Prompt Add Key');

export const addTranslation = createAction(
  '[Translation] Add Translation',
  props<{ translation: Partial<Translation> }>()
);
export const saveTranslation = createAction('[Translation] Save Translation', props<{ translation: Translation }>());

export const removeTranslation = createAction('[Translation] Remove Translation', props<{ translation: Translation }>());

export const removeTranslationKey = createAction(
  '[Translation] Remove Translation Key',
  props<{ group: string; key: string; }>()
);

export const {
  initialState: initialTranslationsState,
  actions: {
    loadAll: loadAllTranslations,
    loadAllSuccess: loadAllTranslationsSuccess,
    create: createTranslation,
    createSuccess: createTranslationSuccess,
    replace: replaceTranslation,
    replaceSuccess: replaceTranslationSuccess,
    delete: deleteTranslation,
    deleteSuccess: deleteTranslationSuccess,
  },
  selectors: { selectAll: allTranslations, selectTotal: totalTranslations, selectCurrentEntity: currentTranslation },
  facade: TranslationsFacadeBase,
} = buildState(Translation);

export function translationReducer(state = initialTranslationsState) {
  return state;
}

export const TRANSLATIONS_FEATURE_KEY = 'translation';
